import "../scss/application.scss";

import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
var $ = require('jquery');




var imagesLoaded = require('imagesloaded');


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();

  var selectTimeout = setTimeout(function (){
      $('#recent').click();
      clearTimeout(selectTimeout);
  }, 3000);

  $('.video-container').click(function (){
      $(this).removeClass("hide");
      $('.recent .project').hide();
      $('.recent .dash').show();
      $('.about').hide();
      $('a').removeClass("active");

  })

  $('#recent').click(function(){

    $('a').removeClass("active");
    $(this).addClass("active");
    $('.about').hide();
    $('.recent .dash').show();
    $('.video-container').addClass("hide");

    var timeout = 100;

    $('.recent .project').each(function(){
        $(this).delay(timeout).fadeIn(0);
        timeout += 50;
    });

  });


  $('#about').click(function(){
      $('.recent .project, .recent .dash').hide();
      $('a').removeClass("active");
      $(this).addClass("active");
      $('.about').show();
      $('.video-container').addClass("hide");
  });


    var player;
    function onYouTubeIframeAPIReady() {
        player = new YT.Player('video', {
            events: {
                'onStateChange': onPlayerStateChange
            }
        });
    }


});


function ShowProjects(){
    $('a').removeClass("active");
    $(this).addClass("active");
    $('.about').hide();
    $('.recent').show();


    var timeout = 100;

    $('.recent .project').each(function(){
        $(this).delay(timeout).fadeIn(0);
        timeout += 75;
    });
}


